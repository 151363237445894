<template>
  <div>
    <div class="card">
      <div class="card-body">
        <h5 class="mb-4">
          <strong>Tambah Obat Baru</strong>
        </h5>
        <a-form :form="form"  @submit="handleSubmit">
          <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Tanggal">
            <a-month-picker style="width: 100%;" :disabled="submitting" placeholder="Select Date" v-decorator="['date', {rules: [{ required: true, message: 'Tanggal harus diisi!' }]}]" />
          </a-form-item>
          <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Total">
            <a-input-number
              style="width: 100%;"
              :min="0"
              :disabled="submitting"
              placeholder="Masukkan Total target"
              v-decorator="['total', {rules: [{ required: true, message: 'Total harus diisi!' }]}]"
            />
          </a-form-item>
          <button type="submit" class="btn btn-success px-5" :disabled="submitting">Simpan Data</button>
        </a-form>
      </div>
    </div>
  </div>
</template>
<script>

import router from '@/router'
import { create, getByDate } from '@/services/axios/api/targetpasien'
import { notification } from 'ant-design-vue'
import { getAll } from '@/services/axios/api/city'
const moment = require('moment')

const labelCol = {
  xs: { span: 24 },
  sm: { span: 4 },
}
const wrapperCol = {
  xs: { span: 24 },
  sm: { span: 12 },
}
export default {
  data: function () {
    return {
      form: this.$form.createForm(this),
      labelCol,
      wrapperCol,
      cityList: [],
      submitting: false,
      statusList: [{ id: 1, name: 'Aktif' }, { id: 0, name: 'Tidak Aktif' }],
    }
  },
  created() {
    this.getCityList()
  },
  methods: {
    async getCityList() {
      const res = await getAll()
      this.cityList = res
    },
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields(async (err, values) => {
        const alreadyMade = await getByDate(moment(values.date._d).format('MM'), moment(values.date._d).format('YYYY'))
        if (!err) {
          this.submitting = true
          console.log('Received values of form: ', values)
          console.log(alreadyMade)
          console.log(moment(values.date._d).format('MM'))
          try {
            if (alreadyMade === null) {
              const res = await create({ year: moment(values.date._d).format('YYYY'), month: moment(values.date._d).format('MM'), total: values.total, date: moment(values.date._d).add(5, 'days') })
              if (res && res.data) {
                notification.success({
                  message: 'Sukses',
                  description: 'Data berhasil ditambahkan',
                  duration: 5,
                })
                router.push('/target-pasien')
              }
            } else {
              notification.warning({
                message: 'Gagal',
                description: 'Data bulan & tahun sudah tersedia, harap update/delete di tabel sebelumnya untuk mengubah data.',
                duration: 5,
              })
            }
            this.submitting = false
          } catch (e) {
            this.submitting = false
          }
        }
      })
    },
  },
}
</script>
